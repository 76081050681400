<template>
  <!-- Settings tab at campaign level -->
  <div class="card min-height-card u-spacing-p-l">
    <loader
      v-show="campaignInfo.status === 'loading'"
      class="fill--parent"
      :loading="campaignInfo.status === 'loading'"
      :color="'#3684bb'"
    />
    <!-- campaign name  -->

    <div class="u-spacing-pb-l">
      <div class="u-spacing-pb-s u-font-size-5 u-font-weight-600">
        Campaign Name:
      </div>
      <div class="u-position-relative">
        <rb-input
          v-model="name"
          class="u-display-flex u-width-480px u-flex-align-items-center"
          @input="updateValue"
        />
      </div>
      <div
        v-if="
          tooltipErrorMessage.length > 0 &&
          campaignMetdataChanged.length &&
          !checking
        "
        class="u-color-red-base u-display-flex u-flex-align-items-center u-spacing-mt-s"
      >
        <rb-icon
          class="u-flex-0 rb-icon--small"
          icon="error-fill"
        />
        <div class="u-flex-0 u-spacing-ml-xs u-font-size-7">
          {{ tooltipErrorMessage }}
        </div>
      </div>
    </div>
    <!-- status -->
    <div class="u-spacing-pb-l">
      <div class="u-spacing-pb-s u-font-size-5 u-font-weight-600">Status:</div>
      <rb-select
        class="filter--token u-max-width-160px"
        :width="'260px'"
        :send-details="true"
        :on-close="setSelectedStatus"
        :options="statusValues"
      >
        <div
          slot="trigger"
          class="u-display-flex u-cursor-pointer u-flex-justify-content-space-between"
        >
          <span class="u-font-size-6">
            {{ (selectedStatus && selectedStatus.title) || 'NA' }}
          </span>
          <rb-icon
            class="rb-icon--medium u-spacing-ml-xs u-color-grey-lighter"
            :icon="'caret-down'"
          />
        </div>
      </rb-select>
    </div>
    <!-- start and end dates -->
    <div class="u-spacing-pb-l u-display-flex">
      <div class="u-spacing-pr-l u-flex-direction-column u-color-grey-lighter">
        <div class="u-spacing-pb-s u-font-size-5 u-font-weight-600">
          Start Date:
        </div>
        <div v-click-outside="hideStartCalendar">
          <span
            class="filter--token u-font-size-6 u-spacing-p-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
            @click="openCalendar('startDate')"
          >
            <rb-icon
              icon="calendar"
              class="rb-icon--small u-spacing-mr-xs"
            />
            <span class="u-spacing-ph-xs u-line-height-1_2">
              {{ dateFormatFilter(campaignDates.start, 'start') }}</span
            >
          </span>
          <datepicker
            ref="startDate"
            v-model="campaignDates.start"
            style="right: -200px"
            name="startDate"
            :disabled="disableStartCalendar"
            :disabled-dates="disableStartDates"
          />
        </div>
      </div>

      <div class="u-flex-direction-column">
        <div class="u-spacing-pb-s u-font-size-5">
          <span class="u-font-weight-600">End Date:</span>
          <span class="u-color-grey-lighter"> Optional</span>
        </div>
        <div
          v-click-outside="hideEndCalendar"
          class="entity-details-settings-calendar"
        >
          <span
            class="filter--token u-font-size-6 u-spacing-p-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
            @click="openCalendar('endDate')"
          >
            <rb-icon
              icon="calendar"
              class="rb-icon--small u-spacing-mr-xs"
            />
            <span class="u-spacing-ph-xs u-line-height-1_2">
              {{ dateFormatFilter(campaignDates.end, 'end') }}</span
            >
          </span>
          <datepicker
            ref="endDate"
            v-model="campaignDates.end"
            style="right: -100px"
            name="endDate"
            :disabled-dates="disableEndDates"
          >
            <div
              slot="beforeCalendarHeader"
              class="no-end-date u-border-bottom u-border-color-grey-xx-light u-border-width-s u-spacing-p-s u-cursor-pointer u-spacing-mb-s"
              :class="noEndDate && 'u-color-white-base u-bg-color-orange-base'"
              data-cy="noEndDate"
              @click="selectNoEndDate"
            >
              No end date
            </div>
          </datepicker>
        </div>
      </div>
    </div>
    <div
      v-if="campaignMetdataChanged.length && updated"
      class="u-spacing-pt-xl"
    >
      <footerButtons
        :button-min-width="'u-min-width-100px'"
        :disable-left-btn="tooltipErrorMessage.length > 0 || checking"
        :left-button-type="
          tooltipErrorMessage.length > 0 || checking ? 'filled' : 'hollow'
        "
        @onClickBtnRight="onCancel"
        @onClickBtnLeft="saveTagsAndMetdata"
      />
    </div>
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
import HttpService from '@/utils/services/http-service';
import { debounce, cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import footerButtons from '@/pages/entity_details/common_components/footerButtons.vue';
import loader from '@/components/basic/loader';
import campaignTags from '@/pages/entity_details/widget_templates/settings/campaignTags.vue';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';

const dashUtils = require('@/utils/common/dashboard-service-utils.js');

export default {
  components: {
    footerButtons,
    Datepicker,
    loader,
    campaignTags
  },
  props: {
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    entityConfig: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      checking: false,
      tooltipErrorMessage: '',
      getState: null,
      updated: false,
      updatedOthers: false,
      campaignTagsKey: 0,
      tagsChanged: false,
      noEndDate: true,
      updateMetadata: null,
      name: null,
      exists: false,
      disableStartDates: {
        from: null,
        to: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      },
      selectedStatus: null,
      campaignDates: {
        start: new Date(),
        end: new Date()
      },
      requestPayload: null,
      settingsRequestMap: {
        state: {
          state: ':state',
          view_old_state: ':view_old_state'
        },
        name: {
          name: ':name',
          view_old_name: ':oldName'
        },
        startDate: {
          new_start_date: ':startDate',
          view_old_start_date: ':view_old_start_date',
          view_start_date: ':view_start_date'
        },
        endDate: {
          new_end_date: ':endDate',
          view_old_end_date: ':oldEndDate',
          view_end_date: ':endDateView'
        }
      },
      statusValues: [
        {
          title: 'Enabled',
          value: 'enabled'
        },
        {
          title: 'Paused',
          value: 'paused'
        }
      ]
    };
  },
  computed: {
    disableEndDates() {
      const currentStartDate = new Date(this.campaignDates?.start);
      currentStartDate.setDate(currentStartDate.getDate() + 1);
      return {
        to: this.disableStartCalendar ? new Date() : currentStartDate,
        from: null
      };
    },
    disableStartCalendar() {
      const tomorrowsDate = new Date();
      tomorrowsDate.setDate(tomorrowsDate.getDate() + 1);
      return (
        new Date(this.campaignInfo?.data?.startDate).setHours(0, 0, 0, 0) <
        tomorrowsDate.setHours(0, 0, 0, 0)
      );
    },
    campaignMetdataChanged() {
      const selectedStatus = this.statusValues.filter(
        (item) => item.value === this.campaignInfo?.data?.state
      );
      const itemsChanged = [];
      if (this.name !== this.campaignInfo?.data?.name) {
        this.updated = true;
        itemsChanged.push({
          value: 'name',
          ':name': this.name?.trim(),
          ':oldName': this.campaignInfo?.data?.name
        });
      }
      if (selectedStatus.length && this.selectedStatus !== selectedStatus[0]) {
        this.updatedOthers = true;
        itemsChanged.push({
          value: 'state',
          ':state':
            this.selectedStatus.value === 'paused' ? 'inactive' : 'active',
          ':view_old_state':
            this.campaignInfo?.data?.state === 'paused' ? 'inactive' : 'active'
        });
      }
      if (
        moment(this.campaignDates.start).format('MMM DD, YYYY') !==
        moment(this.campaignInfo?.data?.startDate).format('MMM DD, YYYY')
      ) {
        this.updatedOthers = true;
        itemsChanged.push({
          value: 'startDate',
          ':startDate': moment(this.campaignDates.start).format('YYYY-MM-DD'),
          ':view_old_start_date':
            this.campaignInfo?.data?.startDate &&
            moment(this.campaignInfo?.data?.startDate).format('MMM DD, YYYY'),
          ':view_start_date':
            this.campaignDates.start &&
            moment(this.campaignDates.start).format('MMM DD, YYYY')
        });
      }
      if (
        moment(this.campaignDates?.end).format('MMM DD, YYYY') !==
        moment(this.campaignInfo?.data?.endDate).format('MMM DD, YYYY')
      ) {
        this.updatedOthers = true;
        itemsChanged.push({
          value: 'endDate',
          ':endDate':
            this.campaignDates?.end === null
              ? this.campaignDates?.end
              : (this.campaignDates?.end &&
                  moment(this.campaignDates?.end).format('YYYY-MM-DD')) ||
                null,
          ':oldEndDate':
            this.campaignInfo?.data?.endDate === null
              ? 'No end date'
              : moment(this.campaignInfo?.data?.endDate).format('MMM DD, YYYY'),
          ':endDateView':
            this.campaignDates?.end === null
              ? 'No end date'
              : (this.campaignDates?.end &&
                  moment(this.campaignDates.end).format('MMM DD, YYYY')) ||
                'No end date'
        });
      } else {
        this.updated = false;
        this.updateValue(this.name);
      }
      return itemsChanged;
    },
    campaignInfo() {
      return this.$store.getters[this.getState];
    }
  },
  watch: {
    campaignInfo: {
      handler(newValue, oldValue) {
        if (newValue?.status === 'loaded') {
          this.setCampaignDetails();
        }
      },
      immediate: true
    },
    'campaignDates.start': {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        const updatedStartDate = new Date(newV);
        updatedStartDate.setDate(updatedStartDate.getDate() + 1);
        if (new Date(newV) >= new Date(this.campaignDates.end)) {
          this.campaignDates.end = this.campaignInfo?.data?.endDate
            ? updatedStartDate
            : null;
          this.campaignDates.start = new Date(newV).setDate(
            new Date(newV).getDate()
          );
        }
      }
    }
  },
  created() {
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.getState = tabConfig?.apiConfigs?.getter;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.requestPayload = tabConfig?.requestPayload;
    this.campaignDates.endDate = this.campaignInfo?.data?.endDate;
  },
  methods: {
    debounceMethod: debounce(function (input) {
      const payload = {
        retailer: 'instacart',
        campaignName: input,
        type: 'CAMPAIGN_DUPLICATE_CHECK'
      };
      this.checking = true;
      HttpService.post('FETCH_AMS_ACTIONS_MANAGER', payload)
        .then((res) => {
          if (
            res?.data?.response?.attributes?.name &&
            res?.data?.response?.attributes?.name !==
              this.campaignInfo?.data?.name
          ) {
            this.tooltipErrorMessage = 'Campaign Name already exists.';
          } else {
            this.tooltipErrorMessage = '';
          }
        })
        .catch((err) => console.log(err))
        .finally(() => (this.checking = false));
    }, 500),
    updateValue(input) {
      if (input === this.campaignInfo?.data?.name && !this.updatedOthers) {
        this.updated = false;
        return;
      }
      if (input.length === 0) {
        this.tooltipErrorMessage = "Campaign Name can't be empty.";
      } else if (input.length > 128) {
        this.tooltipErrorMessage =
          'The campaign name should be within 128 characters';
      } else {
        this.updated = true;
        this.tooltipErrorMessage = '';
      }
      if (input.length <= 128 && input.length > 0) {
        this.debounceMethod(input);
      }
    },
    handleTagUpdate(data) {
      if (data?.add?.length || data?.remove?.length) {
        this.tagsChanged = true;
      } else {
        this.tagsChanged = false;
      }
    },
    onCancel() {
      this.campaignTagsKey++;
      this.tagsChanged = false;
      this.setCampaignDetails();
    },
    saveTagsAndMetdata() {
      const campaignMetadataUdpated = this.campaignMetdataChanged;
      if (!campaignMetadataUdpated?.length) return;
      this.dispatchAction(this.campaignMetdataChanged);
    },
    dateFormatFilter: function (value, type) {
      if (!value || value === -1) return `No ${type} date`;
      return moment(value).format('MMM DD, YYYY');
    },
    setSelectedStatus(context, selection) {
      if (!selection.length) return;
      this.selectedStatus = selection[0];
    },
    setCampaignDetails() {
      const selectedStatus = this.statusValues.filter(
        (item) => item.value === this.campaignInfo?.data?.state
      );
      this.selectedStatus = selectedStatus.length && selectedStatus[0];
      this.name = this.campaignInfo?.data?.name || null;
      this.campaignDates.start = this.campaignInfo?.data?.startDate;
      this.campaignDates.end = this.campaignInfo?.data?.endDate;
      this.noEndDate = !this.campaignDates.end;
    },
    openCalendar(param) {
      if (param === 'endDate') {
        this.noEndDate = !this.campaignDates.end;
      }
      this.$refs[param] && this.$refs[param].showCalendar();
    },
    hideStartCalendar() {
      this.$refs?.startDate?.close();
    },
    hideEndCalendar() {
      this.noEndDate = !this.campaignDates.end;
      this.$refs?.endDate?.close();
    },
    selectNoEndDate() {
      this.campaignDates.end = null;
      this.hideEndCalendar();
    },
    dispatchAction(campaignMetdataChanged) {
      const payload = cloneDeep(this.requestPayload);
      const reqValues = {
        ':entityId': this.entityId,
        ':pageUrl': window.location.href
      };
      for (const item in reqValues) {
        deepReplaceObjectValues(payload, item, reqValues[item]);
      }
      const viewPayload = {};
      const actionPayload = {};
      for (const campaignMetdata of campaignMetdataChanged) {
        const itemReq = this.settingsRequestMap[campaignMetdata.value];
        for (const key in itemReq) {
          viewPayload[key] = campaignMetdata[itemReq[key]];
          if (!key.includes('view_')) {
            actionPayload[key] = campaignMetdata[itemReq[key]];
          }
        }
      }
      payload.actionPayload = { ...payload.actionPayload, ...actionPayload };
      payload.viewPayload = { ...payload.viewPayload, ...viewPayload };
      const keyValue = {
        ':name': null,
        ':view_old_name': null,
        ':view_old_state': null,
        ':view_new_end_date': null,
        ':view_old_end_date': null,
        ':view_old_start_date': null,
        ':startDateView': null
      };
      for (const item in keyValue) {
        deepReplaceObjectValues(payload.viewPayload, item, keyValue[item]);
      }

      if (payload.actionPayload.new_end_date === null) {
        payload.actionPayload.new_end_date = 0;
      }

      this.$store.dispatch(this.updateMetadata, {
        updateRequest: [payload],
        entityId: this.entityId,
        entityType: this.entityConfig.entityType,
        snackbar: this.$snackbar,
        entityName: {
          singular: 'Campaign Setting',
          plural: 'Campaign Settings',
          noCount: true
        },
        routeData: this.$route
      });
    }
  }
};
</script>
<style lang="css">
.entity-details-settings-calendar .vdp-datepicker__calendar {
  padding: 0px;
}
.entity-details-settings-calendar .vdp-datepicker__calendar div {
  padding: 16px;
}
</style>
<style scoped lang="css">
.left-button-campaign-details .rb-button--hollow {
  color: #007cf6 !important;
  border: 1px solid #007cf6 !important;
  background: #fff !important;
}
.left-button-campaign-details .rb-button--hollow:hover {
  color: #fff !important;
  border: 1px solid #007cf6 !important;
  background: #007cf6 !important;
}
.filter--token {
  border-radius: 2px;
}
.min-height-card {
  min-height: 630px;
}
.no-end-date:hover,
.no-end-date:focus {
  background: #ffa800;
}
</style>
